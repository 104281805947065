import { CraftAssetField, CraftLinkItField } from 'tsconfig/craft-types'
import Image from 'next/image'
import './c-featured-items.css'
import classNames from 'classnames'
import { AButton } from '../atoms/a-button'

interface CFeaturedItemsBlockItem {
  heading?: string
  image?: Array<CraftAssetField>
}

export interface CFeaturedItemsBlock {
  id?: string
  heading?: string
  items?: CFeaturedItemsBlockItem[]
  disableBorders?: boolean
  cta?: CraftLinkItField
}

interface CFeaturedItemsProps {
  field: Array<CFeaturedItemsBlock>
}

export const CFeaturedItems = ({ field }: CFeaturedItemsProps) => {
  const { heading, items, disableBorders, cta } = field?.[0] ?? []
  return (
    <div
      className={classNames({
        'c-featured-items__wrapper': !disableBorders,
        'pt-[40px] pb-[10px]': disableBorders,
      })}
    >
      <section className="o-container o-container--xl">
        {heading && (
          <h4 className="text-center text-gray-600 text-3xl md:text-heading mb-6 md:mb-16">
            {heading}
          </h4>
        )}
        {Boolean(items?.length) && (
          <div className="flex flex-column flex-wrap justify-between gap-y-0 md:justify-center md:gap-[45px] md:flex-row">
            {items?.map((item, i) => {
              return (
                <div
                  key={i}
                  className="flex flex-col w-[48%] max-w-[200px] even:mt-[70px] even:md:mt-0 md:w-auto"
                >
                  {item.heading && (
                    <div className="text-center text-lg md:text-xl text-primary-dark-teal font-semibold mb-[10px]">
                      {item.heading}
                    </div>
                  )}
                  {item.image?.[0].url && (
                    <Image
                      className="w-[80px] h-[80px] mx-auto md:mt-auto"
                      src={item.image?.[0].url}
                      alt={item.image?.[0].title ?? ''}
                      width={item.image?.[0].width}
                      height={item.image?.[0].height}
                    />
                  )}
                </div>
              )
            })}
          </div>
        )}
        {cta?.url && (
          <div className="flex justify-center mt-[40px] md:mt-[70px]">
            <AButton
              className="w-full md:w-[300px] normal-case"
              type={cta?.type === 'url' ? 'a' : 'link'}
              href={cta?.url}
              rel={cta?.type === 'url' ? 'noreferrer' : ''}
              target={cta?.target}
              color="neutral-teal"
              size="lg"
            >
              <span>{cta?.text}</span>
            </AButton>
          </div>
        )}
      </section>
    </div>
  )
}

export const CFeaturedItemsFragment = `
    fragment featuredItems on featuredItems_MatrixField {
      ... on featuredItems_block_BlockType {
        id
        heading
        items {
          ... on items_BlockType {
            heading
             image {
              url @transform(mode: "crop", width: 100, immediately: true)
              title
              width
              height
              path
            }
          }
        }
        cta {
          type
          url
          text
          target
        }
        disableBorders
      }
    }
  `
